import API, { setApiAuthState } from "api/client";
import {
  AuthData,
  BootstrapData,
  CSCoreAuthData,
  ConfirmUserForm,
  HtmlString,
  PasswordResetForm,
  RegisterUserForm,
  User,
  UserWithPermissions,
} from "types";

import { ProfileAPI } from "api";
import { envConfig } from "config";
import { jsonToUri } from "@civicscience/chops";

const { authLogoutAPI, authPasswordAPI, authTokenAPI, authTokenRefreshAPI, authRegistrationAPI, authContractAPI } =
  envConfig;

/**
 * Attempt to log in via username and password credentials, receiving a JWT token on success.
 *
 * `POST "<authApi>/token" -H  "accept: application/json" -H  "Content-Type: application/x-www-form-urlencoded" -d`
 */
const getAuthToken = (username: string, password: string): Promise<CSCoreAuthData> => {
  const data = jsonToUri({
    grantType: "",
    scope: "",
    clientSecret: "",
    username,
    password,
  });

  const config = {
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  return API.post<CSCoreAuthData>(`${authTokenAPI}`, data, config).then((res) => res.data);
};

/**
 * Logout the current user.
 *
 * `GET "<authApi>/authLogout" -H "accept: application/json"`
 */
const getLogout = async (): Promise<void> => {
  await API.get(authLogoutAPI);
};

/**
 * Issue a password reset request for the provided email.
 */
const passwordResetRequest = (email: string): Promise<User> => {
  return API.put(`${authPasswordAPI}request-reset`, { email: email }).then((res) => res.data);
};

/**
 * Refresh the user's current JWT token via a refresh_token cookie based request.
 *
 * `GET "<authApi>/token/refresh" -H "accept: application/json"`
 */
const refreshAuthToken = (): Promise<CSCoreAuthData> => {
  return API.get(authTokenRefreshAPI).then((res) => res.data);
};

/**
 * Wrapper over `refreshAuthToken` that returns the data in the `AuthData` format.
 * Basically, tags the data with the "cs-core" kind.
 */
const refreshAuthTokenAsAuthData = async (): Promise<AuthData> => {
  const authData = await refreshAuthToken();
  return { kind: "cs-core", authData };
};

/**
 * Issue a reset password request with the provided dictionary of data.
 */
const resetPassword = async (data: PasswordResetForm): Promise<void> => {
  await API.put(`${authPasswordAPI}reset`, data);
};

/**
 * Issue a request to retrieve contract data.
 */
const getContract = (): Promise<HtmlString> => {
  return API.get(`${authContractAPI}`).then((res) => res.data);
};

/**
 * Issue a request to sign contract.
 */
const signContract = (): Promise<User> => {
  return API.put(`${authContractAPI}`).then((res) => res.data);
};

/**
 * Sign the account contract, and return an updated user.
 * The result is the updated user object populated with the user's permissions.
 */
const signContractWithPermissions = async (): Promise<UserWithPermissions> => {
  const user = await signContract();
  const { permissions } = await ProfileAPI.getPermissions();

  return { ...user, permissions };
};

/**
 * Register a new user in the InsightStore.
 */
const registerUser = (data: RegisterUserForm): Promise<User> => {
  return API.put(`${authRegistrationAPI}register`, data);
};

/**
 * Confirm a new user's registration in the InsightStore.
 */
const confirmUser = (data: ConfirmUserForm): Promise<User> => {
  return API.put(`${authRegistrationAPI}confirm`, data);
};

/**
 * Handles 3 use cases:
 *
 * 1. Initial app load where the user is already authenticated against Auth0.
 * 2. Initial app load where the user is NOT authenticated against Auth0.
 *    In this case, we'll use the Core API to refresh the user's token.
 * 3. After explicit login (against Core API) where the caller will supply auth data.
 *
 * In all cases, we set the auth data and then fetch the initial user data.
 *
 * Note: This function is always called _after_ Auth0's lib is given a chance to
 * check if the user is authenticated via Auth0. Auth0's check is always the first
 * step in the auth flow and we won't proceed until it's done.
 */
const bootstrapApp = async (bootstrapData: BootstrapData): Promise<UserWithPermissions> => {
  const authData = bootstrapData.kind === "token-refresh" ? await refreshAuthTokenAsAuthData() : bootstrapData;
  setApiAuthState(authData);

  return ProfileAPI.getUserWithPermissions();
};

export default {
  getToken: getAuthToken,
  logout: getLogout,
  passwordResetRequest: passwordResetRequest,
  refreshToken: refreshAuthToken,
  resetPassword,
  getContract,
  signContract: signContractWithPermissions,
  register: registerUser,
  confirm: confirmUser,
  bootstrapApp,
};
